.skeleton-page {
    background-color: #FEFEFE;
    padding: 2% 7%;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.skeleton-page .skeleton-header {
    width: 86vw;
    height: 5vh;
    margin-bottom: 10%;
}

.skeleton-page .skeleton-content-header-subtitle {
    width: 10vw;
    height: 3vh;
    margin-bottom: 3%;
    margin-top: 10vh;
    margin-right: auto;
}

.skeleton-page .skeleton-content-header {
    width: 30vw;
    height: 25vh;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.skeleton-page .skeleton-btn {
    width: 13vw;
    height: 7vh;
}

.skeleton-page .skeleton-play {
    width: 13vw;
    height: 5vh;
    margin-top: auto;
    margin-bottom: auto;
}

.skeleton-page .skeleton-about-author-title {
    width: 15vw;
    height: 3vh;
    margin-bottom: 5%;
}

.skeleton-page .skeleton-about-author-content {
    width: 15vw;
    height: 12vh;
}

.skeleton-page .skeleton-hero-img {
    height: 80vh;
    width: 25vw;
    margin-top: -5vh;
}

.skeleton-page .skeleton-row {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    justify-content: space-between;
}

.skeleton-page .skeleton-column {
    display: flex;
    flex-direction: column;
}