@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../src/fonts/Montserrat.ttf) format('truetype');
}

@font-face {
  font-family: 'Floripa';
  src: local('Floripa'), url(../src/fonts/Floripa.ttf) format('truetype');
}

* {
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  color: #FFF;
}

html {
  background-color: #151515;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #151515;
  padding: 0;
  margin: 0;
  width: 100%;
}

p {
  margin: 0;
}

.row {
  /* position: relative; */
  padding: 0;
}


.-muted {
  color: rgba(255, 255, 255, 0.4);
}

.-bold {
  font-weight: 700;
}

button, a, img {
  position: relative;
  z-index: 1;
}

.main-page {
  position: relative;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.section {
  padding: 0 7.3%;
}

.main-page::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.programs-block {
  flex-direction: column;
}

.section-header {
  position: relative
}

.header-subtitle {
  position: relative
}

.section-block, .footer-block {
  padding-top: 160px;
}

.core-floating-button {
    width: 112px;
    height: 40px;
    position: fixed;
    right: 20px;
    bottom: 40px;
    background-color: #323237;
    z-index: 10;
    color: #FEFEFE;
    border: none;
    opacity: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    transition: opacity 450ms ease;
}

.core-floating-button img {
  margin-left: 11px;
}

@media screen and (max-width: 767px) {
  .row-header-menu {
      height: 60px;
      padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .row-header-menu {
     height: 120px;
     padding: 0;
  }
}

@media screen and (min-width: 1200px) {
  .grid-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: calc(50% - 1494px/2 + 213px);
    top: 0px;
    background-image: url("pages/desktop/mainPage/static/img/grid.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .section {
    padding: 0 5%!important
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
    .section-block, .footer-block {
      padding-top: 120px!important;
    }
}
  
@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .section-block, .footer-block {
      padding-top: 120px!important;
    }
}


@media screen and (min-width: 481px) and (max-width: 768px) {
  .section {
    padding: 0 3%!important
  }

  .section-block, .footer-block {
    padding-top: 80px!important;
  }
}