.mobile-skeleton-page {
    background-color: #FEFEFE;
    padding: 2% 5%;
    width: 100%;
    min-height: 100vh;
}

.skeleton-item {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    border-radius: 6px;
    padding: 0 !important;
    /*#D1D1D1*/
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.mobile-skeleton-page .skeleton-header {
    width: 90vw;
    height: 5vh;
    margin-bottom: 10%;
}

.mobile-skeleton-page .skeleton-content-header-subtitle {
    width: 40vw;
    height: 3vh;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
}

.mobile-skeleton-page .skeleton-content-header {
    width: 80vw;
    height: 15vh;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.mobile-skeleton-page .skeleton-btn {
    width: 90vw;
    height: 7vh;
    margin-bottom: 5%;
}

.mobile-skeleton-page .skeleton-play {
    width: 50vw;
    height: 3vh;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 35%;
}

.mobile-skeleton-page .skeleton-about-author-title {
    width: 35vw;
    height: 3vh;
    margin-bottom: 5%;
}

.mobile-skeleton-page .skeleton-about-author-content {
    height: 12vh;
    width: 35vw;
}

.mobile-skeleton-page .skeleton-hero-img {
    height: 40vh;
    width: 50vw;
    margin-top: -5vh;
}

.mobile-skeleton-page .skeleton-row {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    justify-content: space-between;
}

.mobile-skeleton-page .skeleton-column {
    display: flex;
    flex-direction: column;
}